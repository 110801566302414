@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;700&display=swap');
* {
  font-family: "Maven Pro", sans-serif !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #27154f;
  text-transform: none !important;
}

*:focus {
  outline: none;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.App {
  text-align: center;
  background-color: #dce0e9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 5px;
}

.container {
  max-width: 1268px;
  width: 100%;
  padding: 0;
}

.header {
  background-color: #dce0e9;
  box-shadow: none;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 82px;
  position: relative;
  background-color: #dce0e9;
}

.header__container:before {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 20px rgb(0 0 0 / 30%);
  top: 50%;
  bottom: 0;
  left: 100px;
  right: 100px;
  border-radius: 30%;
}
.header__buttons {
  display: flex;
}

.header__button {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #27154f;
}

.header__button svg {
  margin-right: 12px;
}

main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 112px 0 116px;
  max-width: 1368px;
  margin: 0 auto;
  width: 100%;
}

main .container {
  display: flex;
  flex-direction: column;
}

.hero{
  background-size: cover;
  border-radius: 30px;
  padding: 120px 24px;
  margin-bottom: 120px;
  position: relative;
}

.hero-one {
  background-image: url(./assets/background-stage.jpg);
}

.hero-two {
  margin-top: 120px;
  background-image: url(./assets/background-language-generator.jpg);
}

.hero-three {
  background-image: url(./assets/background-image-generator.jpg);
}

.hero__title {
  font-size: 54px;
  color: white;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 22px;
}

.hero-one .hero__title {
  margin-top: 10px;
}

.hero__text {
  font-size: 22px;
  line-height: 32px;
  max-width: 728px;
  margin: 0 auto;
  color: white;
}

.hero__event {
  max-width: 916px;
  margin: 50px auto 0;
}

.hero__date {
  display: flex;
  justify-content: space-between;
}

.hero__date .date__block {
  padding: 28px;
  border-radius: 25px;
  flex: 1;
}

.hero__date .date__block h3{
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  color: #27154F;
}

.hero__date .date__block p{
  margin-top: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: #27154F;
}

.hero__date .date__day {
  margin-right: 12px;
}

.hero__date .date__time {
  margin-left: 12px;
}

.hero__location {
  margin-top: 24px;
}

.hero__location .location__block{
  padding: 28px;
  border-radius: 25px;
}

.hero__location h3{
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #27154F;
}

.hero__location p{
  margin-top: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #27154F;
}

.hero__location a{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #C92775;
  text-decoration: none;
}

.hero__location a:hover{
  text-decoration: underline;
}

.hero__event-button {
  display: inline-block;
  margin-top: 50px;
  background-color: #fdffa3;
  color: #000154;
  border-radius: 30px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  padding: 15px 40px;
  text-decoration: none;
}


.hero__event-button:hover {
  cursor: pointer;
  box-shadow: 0px 5px 10px 5px rgba(34, 60, 80, 0.3);
  background-color: #fdffa3;
}

.connector {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 50%;
  transform: translate(50%, 50%);
  bottom: 0;
}

.information {
  max-width: 940px;
  margin: 0 auto;
}

.information__title {
  padding: 0 12px;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #C92775;
}

.information__description {
  margin-top: 40px;
  padding: 0 12px;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #000154;
}

.information__block {
  margin-top: 32px;
  display: flex;
}

.information__text {
  max-width: 552px;
  padding: 0 12px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #000154;
}

.information__aside {
  max-width: 352px;
  padding: 0 12px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #000154;
}

.information__button {
  display: inline-block;
  margin-top: 32px;
  padding: 11px 40px;
  background: #27154F;
  color: #EAEAEA;
  border-radius: 25px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  font-style: normal;
  line-height: 26px;
}

.information__button:hover {
  cursor: pointer;
  box-shadow: 0px 5px 10px 5px rgba(34, 60, 80, 0.3);
  background: #27154F;
}

.showcase {
  margin-top: 120px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.showcase__aside {
  position: relative;
  top: 100px;
}

.showcase__row-1 {
  display: flex;
  align-items: self-end;
  -webkit-align-items: flex-end;
}
.showcase__row-2 {
  display: flex;
  align-items: self-start;

}

.showcase__img {
  font-size: 0;
}

.showcase img {
  width: 100%;
  font-size: 0;
}

.showcase__right {
  display: none;
}

.contact {
  margin-top: 120px;
}

.contact-title {
  font-size: 54px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
}

.contact-subtitle {
  margin-top: 22px;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.contact-block {
  max-width: 470px;
  margin: 48px auto 0;
}

.contact-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-item {
  font-size: 0;
  margin: 0 12px;
  border-radius: 10px;
  overflow:hidden;
}

.contact-item img{

  width: 100%;
  height: 100%;
}

.contact-name {
  padding: 7px;
  background: #EBF38D;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
}

.contact-info {
  margin: 40px auto;
  padding: 0 12px;
}

.contact-address {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  text-align: left;
  color: #000154;
}

.contact-mail,
.contact-webSite,
.contact-newsletterBtn {
  display: block;
  color: #C92775;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: left;
  text-decoration: none;
}

.contact-mail:hover,
.contact-webSite:hover,
.contact-newsletterBtn:hover {
  cursor: pointer;
  text-decoration: underline;
}

.contact-newsletterBtn {
  margin-top: 22px;
}



.actions {
  width: fit-content;
  font-size: 18px;
  margin: 60px auto 0;
  display: grid;
  grid-template-columns: 200px 200px 200px;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 20px 30px;
  grid-template-areas:
    "actions__select-label actions__select-wrapper actions__select-wrapper"
    "actions__input-label actions__input-wrapper actions__input-wrapper"
    ". actions__button-wrapper .";
}

.actions__select-label {
  grid-area: actions__select-label;
  justify-self: end;
  align-self: center;
  color: white;
}

.actions__select-wrapper {
  grid-area: actions__select-wrapper;
  justify-self: baseline;
  width: 100%;
  max-width: 400px;
}

.actions__select,
.actions__input {
  width: 100%;
  border-radius: 30px;
  font-size: 18px;
  color: #000154;
  background-color: white;
  text-align: left;
  padding: 0 15px;
}

.actions__input-label {
  grid-area: actions__input-label;
  justify-self: end;
  align-self: center;
  color: white;
}

.actions__input-wrapper {
  grid-area: actions__input-wrapper;
  justify-self: baseline;
  width: 100%;
  max-width: 400px;
}

.actions__button-wrapper {
  grid-area: actions__button-wrapper;
}
.actions__generate-button {
  background-color: #fdffa3;
  color: #000154;
  border-radius: 30px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 20px;
}

.actions__generate-button:hover {
  background-color: #fdffa3;
  box-shadow: 0px 5px 10px 5px rgba(34, 60, 80, 0.3);
  color: #000154;
}

.actions__generate-button:disabled {
  background-color: #fdffa3!important;
  color: #000154;
  opacity: .5;
}

.result-section {
  margin: 0 auto 120px;
  max-width: 728px;
  width: 100%;
}

.result-preview {
  padding: 44px;
  border: 3px white dashed;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  font-weight: 700;
}

.result-preview__title {
  font-size: 26px;
  margin-bottom: 32px;
}

.result-preview__text {
  line-height: 26px;
  text-align: center;
}

.result-preview__error {
  color: red;
}

.result-preview__loader {
  width: 64px;
  height: 64px;
  margin-bottom: 21px;
}

.result-generated__heading {
  display: flex;
  flex-direction: column;
  font-size: 26px;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 20px;
}

.result-generated__card {
  background-color: white;
  border-radius: 10px;
}

.result-generated__text {
  padding: 30px;
  border-bottom: 1px solid#8C9AB8;
  text-align: justify;
  line-height: 26px;
}

.result-generated__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 30px;
}

.image-selector {
  max-width: 916px;
  width: 100%;
  margin: 0 auto;
}

.image-selector__main-loader {
  width: 150px;
  margin: 100px 0;
}

.image-selector__row {
  display: flex;
  margin-bottom: 28px;
  flex-wrap: wrap;
}

.image-selector__error {
  font-size: 14px;
  color: red;
  font-weight: 700;
  width: 100%;
  margin: 24px auto 0;
}

.image-selector__preview-button {
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  width: 70px;
  height: 70px;
}

.image-selector__previews-wrapper {
  display: flex;
  margin-left: 70px;
}

.image-selector__preview-button + .image-selector__preview-button {
  margin-left: 24px;
}

.image-selector__selected-image-wrapper {
  border-radius: 10px;
  position: relative;
}

.image-selector__image-block {
  max-width: 634px;
}

.image-selector__selected-image {
  width: 100%;
  border-radius: 10px;
}

.image-selector__description {
  margin-top: 16px;
  font-weight: 700;
}

.image-selector__loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(220, 224, 233, 0.5);
}

.image-selector__result-loader {
  position: absolute;
  width: 20%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.image-selector__preview-button--selected {
  border: 3px solid #c92775;
}

.file-loader {
  background-color: #ebf38d;
  position: relative;
  margin-left: 24px;
  border-radius: 10px;
  flex-direction: column;
  height: 70px;
  padding: 0 50px;
}

.file-loader__title {
  font-size: 18px;
  font-weight: 700;
}

.file-loader__note {
  font-size: 14px;
}

.file-loader__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
}

.image-specs {
  max-width: 258px;
  width: 100%;
  margin-left: 24px;
}

.image-specs__row:first-of-type {
  padding-bottom: 12px;
  border-bottom: 1px dashed #27154f;
}

.image-specs__row {
  display: flex;
  justify-content: space-between;
}

.image-specs__row + .image-specs__row {
  margin-top: 12px;
}

.image-specs__label {
  font-weight: 700;
  text-align: left;
}

.image-specs__download-button {
  margin-left: -8px;
  margin-right: auto;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #c92775;
}

.image-specs__download-button svg {
  margin-right: 12px;
}

.webinars {
  padding: 0 30px;
}

.webinars__title {
  font-size: 54px;
  font-weight: 700;
  margin-bottom: 22px;
}

.webinars__text {
  font-size: 22px;
  line-height: 32px;
  max-width: 728px;
  margin: 0 auto;
}

.webinars__link:hover {
  text-decoration: none;
}

.webinars__heading {
  margin-bottom: 60px;
}

.webinars__list {
  max-width: 728px;
  margin: 0 auto 60px;
  border-top: 1px dashed #27154f;
}

.webinar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  border-bottom: 1px dashed #27154f;
  flex-wrap: wrap;
}

.webinar-item--closed .webinar-item__column {
  opacity: 0.5;
}

.webinar-item__status {
  background-color: #27c9b6;
  border-radius: 50%;
  width: 19px;
  height: 19px;
}

.webinar-item--closed .webinar-item__status {
  background-color: #c92775;
}

.webinar-item__value {
  font-size: 24px;
  font-weight: 700;
  margin-top: 4px;
}

.webinar-item__button-wrapper {
  min-width: 230px;
  display: flex;
}

.webinar-item__button,
.webinar-modal__button {
  display: block;
  font-weight: 700;
  font-size: 16px;
  color: #eaeaea;
  background-color: #27154f;
  border-radius: 25px;
  padding: 13px 36px;
  line-height: 26px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-left: auto;
}

.webinar-item__button:hover,
.webinar-modal__button:hover {
  background-color: #27154f;
  box-shadow: 0px 5px 10px 5px rgba(34, 60, 80, 0.3);
}

.webinar-item__button:disabled {
  color: #27154f;
  background-color: #afadc3;
}

.rocket-logo {
  position: relative;
  margin-bottom: 40px;
}

.rocket-logo__divider {
  position: absolute;
  left: 50%;
  top: 85%;
  transform: translate(-50%, -50%);
}

.rocket-logo__image {
  position: relative;
}

.copy-button {
  font-weight: 700;
  color: #27154f;
  font-size: 16px;
}

.footer {
  max-width: 1368px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 50px;
}

.footer * {
  color: white;
}

.footer__inner {
  background: url(./assets/background-footer.jpg);
  border-radius: 30px;
  padding: 55px 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__info {
  display: flex;
  align-items: center;
}

.footer__copyrights {
  margin-right: 24px;
  text-align: start;
}

.arrow-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 700;
}

.arrow-link:first-of-type {
  margin-right: 24px;
}

.arrow-link:hover {
  text-decoration: underline;
}

.arrow-link svg {
  margin-right: 12px;
}

.webinar-modal {
  border-radius: 20px;
  padding: 24px;
  max-width: 646px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.webinar-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -15px 0 32px;
}

.webinar-modal__close-button {
  margin: 0 -15px 0 0;
}

.webinar-modal__title {
  color: #000154;
  font-size: 22px;
}

.webinar-modal__body {
  margin-bottom: 32px;
}

.webinar-modal__description {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000154;
}

.webinar-modal__date {
  font-weight: 700;
}

.webinar-modal__button {
  margin-left: auto;
}
.email-form {
  width: 100%;
  margin-bottom: 16px;
}

.email-label{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 0 12px;
}

.email-input {
  width: 100%;
  background: #FFFFFF;
  color: #000154;
  box-sizing: border-box;
  border-radius: 30px;

}

.email-input input{
  padding: 14px 32px;

}

@media (max-width: 1024px) {
  .hero-one {
    padding: 60px 24px 90px;
  }

  .hero-one .hero__title {
    font-size: 32px;
  }

  .hero-one .hero__text {
    font-size: 18px;
  }

  .hero__date .date__block p {
    font-size: 28px;
  }
  .hero__date .date__block h3,
  .hero__location h3,
  .hero__location p,
  .hero__location a
  {
    font-size: 22px;
  }


  .footer__inner {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .footer__info {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .footer__info > * {
    margin-top: 20px;
  }
}

@media (max-width: 960px) {
  .image-selector {
    margin: 0 auto 60px;
  }

  .image-selector__row {
    flex-direction: column;
    align-items: center;
  }

  .image-selector__previews-wrapper {
    margin: 0 auto 20px;
  }

  .file-loader {
    margin: 0 auto;
  }

  .image-selector__selected-image-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .image-specs {
    margin-top: 20px;
  }
}

@media (max-width: 840px) {
  .information__title {
    font-size: 36px;
    line-height: 42px;
  }

  .information__description {
    margin-top: 36px;
    font-size: 20px;
    line-height: 29px;
  }

  .information__block {
    margin-top: 29px;
  }

  .information__text {
    max-width: 497px;
    font-size: 20px;
    line-height: 29px;
  }

  .information__aside {
    font-size: 20px;
    line-height: 29px;
  }

  .information__button{
    margin-top: 29px;
    padding: 10px 36px;
    border-radius: 22px;
    font-size: 15px;
    line-height: 23px;
  }
}

@media (max-width: 768px) {
  main {
    padding: 112px 0 50px;
  }

  .header__container {
    padding: 24px;
  }

  .logo {
    max-width: 100px;
  }

  .hero {
    padding: 50px 24px 90px;
  }

  .hero__event {
    margin: 35px auto 0
  }

  .hero__title,
  .webinars__title {
    font-size: 32px;
  }

  .hero__text,
  .webinars__text {
    font-size: 18px;
  }

  .hero__date {
    flex-direction: column;
  }
  .hero__date .date__day,
  .hero__date .date__time {
    margin: 5px 0;
  }

  .hero__event-button {
    padding: 12px 16px
  }

  .information__title {
    font-size: 29px;
    line-height: 34px;
  }

  .information__description {
    margin-top: 26px;
    font-size: 18px;
    line-height: 24px;
  }

  .information__block {
    margin-top: 24px;
  }

  .information__text {
    max-width: 403px;
    font-size: 16px;
    line-height: 24px;
  }

  .information__aside {
    font-size: 16px;
    line-height: 24px;
  }

  .information__button{
    margin-top: 24px;
    padding: 12px 29px;
    border-radius: 18px;
    font-size: 16px;
    line-height: 19px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 332px;
  }

  .webinar-item__value {
    font-size: 22px;
  }

  .footer__inner {
    padding: 24px;
  }
}

@media (max-width: 650px) {

  .information__title {
    text-align: center;
    margin-top: 15px;
  }

  .information__description {
    text-align: center;
  }

  .information__block {
    margin-top: 23px;
    flex-direction: column;
  }

  .information__text {
    max-width: 100%;
    text-align: center;
  }
  .information__aside {
    max-width: 100%;
    margin-top: 23px;
    text-align: center;
  }

  .showcase {
    flex-direction: column;
    margin-top: 80px;
  }
  .showcase__aside {
    height: 100px;
    top: 0;
    display: flex;
    display:-webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
  }

  .showcase__aside-left {
    display: none
  }

  .showcase__row-1,
  .showcase__row-2 {
    height: 100px;
    justify-content: center;
  }
  .showcase__img {
    height: 100%;
    padding: 1px;
  }
  .showcase__img img{
    height: 100%;
    object-fit: cover;
  }

  .showcase__left {
    display: none;
  }

  .showcase__right {
    display: block;
  }

  .contact {
    margin-top: 80px;
  }

  .result-section {
    margin: 0 auto 60px;
  }
  .image-selector {
    margin: 0 auto;
  }
}

@media (max-width: 600px) {

  .contact-title {
    font-size: 46px;
    line-height: 50px;
  }

  .contact-subtitle {
    font-size: 19px;
    margin-top: 15px;
  }

  .contact-block {
    margin: 40px auto 0;
  }

  .webinar-item__button-wrapper {
    width: 100%;
    margin-top: 20px;
  }

  .webinar-item__button-wrapper,
  .webinar-item__button {
    width: 100%;
  }

  .webinar-item {
    padding: 30px 0;
  }
}

@media (max-width: 450px) {

  .hero-one .hero__title {
    font-size: 28px;
  }
  .hero__event {
    margin: 35px auto 0
  }
  .hero__date .date__block p {
    font-size: 24px;
    margin-top: 5px
  }
  .hero__location p {
    margin-top: 5px
  }
  .hero__date .date__block,
  .hero__location .location__block{
    padding: 15px;
  }
  .hero__date .date__block h3,
  .hero__location h3,
  .hero__location p,
  .hero__location a {
    font-size: 18px;
    line-height: 26px;
  }

  .contact-title {
    font-size: 36px;
    line-height: 40px;
  }

  .contact-subtitle {
    font-size: 20px;
    margin-top: 15px;
  }

  .contact-block {
    margin: 30px auto 0;
  }

  .contact-item {
    margin: 0 5px;
  }

  .contact-info {
    margin: 30px auto;
    padding: 0 5px
  }

  .contact-address,
  .contact-mail,
  .contact-webSite,
  .contact-newsletterBtn {
    font-size: 20px;
    line-height: 28px;
  }

  .webinar-modal__body {
    margin-bottom: 0;
  }

  .webinar-modal__button {
    padding: 13px 15px;
    margin: 10px auto;
  }
}

@media (max-width: 450px) {
  .hero__location a {
    font-size: 14px;
  }
}